<template>
  <div id="app">
    <HSStyling />
    <HSAlertStack ref="alert" class="stack" />
    <HSNavbar
      v-if="!$auth.loading"
      class="hsNavbar"
      color="dark"
      :loggedIn="$auth.isAuthenticated"
    >
      <div class="d-flex menu-content">
        <div class="nav-item" @click="handleNavigation('/products')">
          <a>Products</a>
        </div>
        <div class="nav-item" @click="handleNavigation(landingPageUri)">
          <a>Health Scholars</a>
          <HSIcon icon="undo" color="white" size="25px" />
        </div>
        <div
          v-if="entitlementLink"
          class="nav-item"
          data-test="entitlement-link"
          @click="handleNavigation(entitlementDashboardUri)"
        >
          <a>Entitlement Dashboard</a>
          <HSIcon icon="open_in_new" color="white" />
        </div>
      </div>
      <template #actions>
        <HSButton
          data-test="login-button"
          variant="is-light"
          :label="loginButtonLabel"
          @onClick="setLogin"
        />
      </template>
    </HSNavbar>
    <router-view
      @accountLoaded="handleAccountLoaded"
      @facilityLoaded="handleFacilityLoaded"
      @departmentLoaded="handleDepartmentLoaded"
    ></router-view>
    <EditAccountCodeModal :isOpen.sync="isModalOpen" :account="oAccount" />
  </div>
</template>

<script>
import {
  HSAlertStack,
  HSButton,
  HSIcon,
  HSStyling,
} from "@healthscholars/healthscholars-vue";
import { HSNavbar } from "@healthscholars/healthscholars-vue";
import EditAccountCodeModal from "./components/EditAccountCodeModal.vue";

export default {
  components: {
    EditAccountCodeModal,
    HSAlertStack,
    HSButton,
    HSIcon,
    HSNavbar,
    HSStyling,
  },
  computed: {
    entitlementLink() {
      return this.$auth.isAuthenticated && this.$route.name === "account";
    },
    loginButtonLabel() {
      return this.$auth.isAuthenticated ? "Logout" : "Login";
    },
  },
  data() {
    return {
      entitlementDashboardUri: process.env.VUE_APP_ENTITLEMENT_DASHBOARD_URI,
      landingPageUri: process.env.VUE_APP_LANDING_PAGE_URI,
      isModalOpen: false,
      oAccount: {},
    };
  },
  methods: {
    setLogin() {
      if (!this.$auth.isAuthenticated) {
        this.$auth.loginWithRedirect();
      } else {
        this.$auth.logout({
          returnTo: window.location.origin,
        });
      }
    },
    handleNavigation(location) {
      window.location.assign(location);
    },
    handleAccountLoaded({ accountId }) {
      this.$store.dispatch("loadSalesforceAccountCourses", accountId);
      this.$store.dispatch("loadSalesforceAccountFacilities", accountId);
      this.$store.dispatch("loadUnAssignedFacilities");
      this.$store.dispatch("loadSalesforceAccountProducts", accountId);
    },
    handleFacilityLoaded({ facilityId }) {
      this.$store.dispatch("loadFacility", facilityId);
      this.$store.dispatch("loadFacilityDepartments", facilityId);
      this.$store.dispatch("loadUnAssignedDepartments", { facilityId });
    },
    handleDepartmentLoaded({ departmentId }) {
      this.$store.dispatch("loadDepartment", departmentId);
    },
  },
  mounted() {
    this.$store.dispatch("initializeApp", this.$refs.alert);
    // eslint-disable-next-line no-console
    console.info("loaded version", process.env.VUE_APP_VERSION);
  },
  updated() {
    if (this.$auth.isAuthenticated) {
      this.$store.commit("setUserToken", { token: this.$auth.token });
    }
  },
  watch: {
    "$store.state.activeAccount": function () {
      this.oAccount = this.$store.state.activeAccount;
      this.isModalOpen = true;
    },
  },
};
</script>

<style lang="sass" scoped>
#app
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  color: #2c3e50
  .hs-navbar--menu__start
    width: 100%
  nav,
  nav > div
    background-color: #403c3d !important
    padding: 5px 0
  .nav-item
    color: #ece0e0
    padding: 5px 10px
    display: flex
    cursor: pointer
    font-weight: bold
    &:hover > a
      text-decoration: underline
    a
      color: #ece0e0
    span
      padding: 0 5px
  .hs-alert-stack
    ::v-deep
      .hs-alert-active
        position: relative !important
        z-index: 999 !important
        top: 50px
  .hsNavbar
    color: white
    z-index: 2
    ::v-deep
      .hs-navbar--menu
        .hs-navbar--menu__start
          width: 100%
</style>
<style lang="sass">
nav
  .list.list-unstyled
    flex-wrap: wrap
</style>
