<template>
  <div v-show="isModalActive" class="modal-wrapper">
    <div class="container modal">
      <div class="close-modal" @click="onModalClose">
        <HSIcon icon="close" />
      </div>

      <div class="modal-body">
        <h4>Reset account code</h4>
        <p>
          The current account code for
          <strong>{{ account.name || "" }}</strong> is
          <strong>{{ account.code || "" }}</strong>
        </p>
        <div class="warning-message" data-test="warning-message">
          <div class="message-content">
            <HSIcon class="alert-icon" icon="info" color="red" size="20px" />
            Attention! By reseting this account code all headsets will need to
            update the code before users can log in. Are you sure you want to
            proceed?
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <HSButton data-test="close-modal-btn" @onClick="onModalClose">
          Cancel
        </HSButton>
        <HSButton data-test="confirm-edit-btn" @onClick="onChangeAccountCode">
          Continue
        </HSButton>
      </div>
    </div>
  </div>
</template>

<script>
import { HSButton, HSIcon } from "@healthscholars/healthscholars-vue";

export default {
  components: {
    HSButton,
    HSIcon,
  },
  data() {
    return {
      isModalActive: this.isOpen,
      oAccount: {
        name: "",
        code: "",
      },
    };
  },
  methods: {
    setScrolling() {
      const htmlBody = document.body;
      if (this.isModalActive) {
        htmlBody.style.height = "100%";
        htmlBody.style.overflow = "hidden";
        return;
      }
      htmlBody.style.overflow = "visible";
    },
    onModalClose() {
      this.isModalActive = false;
      this.$store.dispatch("establishEditingAccountCode", this.oAccount);
    },
    async onChangeAccountCode() {
      const account = this.account;
      await this.$store.dispatch("updateAccountCode", account);
      alert(`Account ${this.account.name} successfully updated!`);
      this.onModalClose();
    },
  },
  watch: {
    isOpen() {
      this.isModalActive = this.isOpen;
    },
    isModalActive(value) {
      this.setScrolling();
      this.$emit("update:isOpen", value);
    },
  },
  props: {
    isOpen: { type: Boolean, default: false },
    account: { type: Object, default: () => {} },
  },
};
</script>

<style scoped lang="sass">
.modal-wrapper
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 100
  background-color: rgba(0, 0, 0, 0.5)
  .modal
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 90%
    height: 90%
    max-width: 600px
    max-height: 290px
    background-color: white
    border-radius: 7px
    padding: 20px
    overflow-y: auto
  .close-modal
    position: absolute
    top: 10px
    right: 10px
    cursor: pointer
  .modal-body
    .message-content
      border-radius: 6px 6px 6px 6px
      border: 1px solid #be3f4e
      align-content: center
      background: #feeaec
      display: flex
      font-size: 16px
      font-weight: 400
      margin-bottom: 0.8rem
      padding: 0.563rem
      .alert-icon
        align-content: center
        padding-top: 4px
        display: flex
        margin-right: 0.5rem
        color: #be3f4e !important
  .buttons-container
    display: flex
    align-items: center
    justify-content: end
    margin-top: 30px
    button
      transition: background 0.3s
      cursor: pointer
      color: white
      background: rgb(5, 206, 5)
      &:hover
        box-shadow: none
        background: darken(rgb(5, 206, 5), 10%)
      &:nth-last-child(even)
        background: rgb(246, 41, 41)
        margin-right: 15px
        &:hover
          background: darken(rgb(246, 41, 41), 15%)
</style>
