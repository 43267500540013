<template>
  <div id="facility-view" class="facility-view p-4">
    <h2 class="w-100 pl-4" data-test="salesforce-facility-name">
      {{ getFacilityInfo.name }}
    </h2>
    <div class="flex-row d-flex justify-content-around">
      <div class="w-50 p-4">
        <div class="ml-2 d-flex justify-content-end" v-if="!isEditMode">
          <HSButton
            class="mr-2"
            data-test="salesforce-facility-edit-button"
            label="Edit Facility"
            type="is-info"
            @onClick="enterEditMode"
          />

          <HSButton
            data-test="salesforce-facility-delete-button"
            label="Delete Facility"
            type="is-danger"
            @onClick="deleteFacility"
          />
        </div>
        <form
          @submit.prevent="saveEditFacility"
          :class="`salesforce-facility-form${isEditMode ? '__edit' : ''}`"
          class="ml-2"
          data-test="salesforce-facility-form"
        >
          <div class="facility-form-container" :key="facilityFormKey">
            <HSField
              class="mt-3 w-100"
              data-test="salesforce-facility-input-name"
              inputType="text"
              label="Facility Name"
              variant="outlined"
              :readonly="!isEditMode"
              v-model="oFacility.name"
            />
            <HSField
              class="w-100"
              data-test="salesforce-facility-input-address"
              inputType="text"
              label="Address"
              variant="outlined"
              :readonly="!isEditMode"
              v-model="oFacility.address"
            />
            <div class="d-flex mb-3 w-100">
              <HSField
                data-test="salesforce-facility-input-city"
                inputType="text"
                label="City"
                variant="outlined"
                :readonly="!isEditMode"
                v-model="oFacility.city"
              />
              <HSField
                data-test="salesforce-facility-input-state"
                inputType="text"
                label="State"
                variant="outlined"
                :readonly="!isEditMode"
                v-model="oFacility.state_province"
              />
            </div>
            <HSField
              data-test="salesforce-facility-input-pc"
              inputType="text"
              label="Postal Code"
              variant="outlined"
              :readonly="!isEditMode"
              v-model="oFacility.postal_code"
            />
          </div>
          <p
            v-show="formInvalid"
            class="has-warning"
            data-test="account-create-error"
          >
            You must fill all the fields in the form.
          </p>
          <div class="d-flex justify-content-end" v-if="isEditMode">
            <HSButton
              data-test="salesforce-facility-button-cancel"
              label="Cancel"
              type="is-danger"
              @onClick="cancelEditFacility"
            />
            <HSButton
              class="ml-2"
              data-test="salesforce-facility-button-save"
              label="Save"
              type="is-success"
              @onClick="saveEditFacility"
            />
          </div>
        </form>
      </div>
      <div class="w-50 p-4">
        <div v-if="departmentSection === ''">
          <div class="d-flex flex-column">
            <HSButton
              data-test="facility-button-create-department"
              class="mb-3"
              label="Create New Department"
              variant="is-light"
              @onClick="setDepartmentSection('createNewDepartment')"
            />
            <HSButton
              class="mb-3"
              data-test="facility-button-add-department"
              label="+ Add Department"
              variant="is-light"
              @onClick="setDepartmentSection('addDepartments')"
            />
          </div>
          <HSDataTable
            class="csm-data-table"
            data-test="facility-departments-table"
            title="Departments"
            :itemsPerPage="10"
            :headers="dataTableHeaders"
            :items="getDepartmentFacilities"
            :sortBy="dataTableSortBy"
          >
            <template #name="{ item }">
              <HSButton
                class="mr-3 item-button"
                data-test="facility-department-dissociate-button"
                icon="close"
                color="black"
                variant="is-light"
                @onClick="removeDepartmentFromFacility(item.id)"
              />

              <router-link
                :to="`/department/${item.id}`"
                data-test="facility-department-link"
              >
                {{ item.name }}
              </router-link>
            </template>
          </HSDataTable>
        </div>
        <div
          v-else-if="departmentSection === 'addDepartments'"
          data-test="facility-add-department-section"
        >
          <div
            class="d-flex justify-content-between align-items-center mb-3"
            data-test="facility-add-department-section-title"
          >
            <h4>Departments</h4>
            <div class="d-flex align-items-center mb-3">
              <HSButton
                class="mr-2"
                data-test="facility-add-department-button-cancel"
                label="Cancel"
                type="is-danger"
                @onClick="resetDepartmentMenu"
              />
              <HSButton
                data-test="facility-add-department-button-save"
                label="Save"
                type="is-success"
                @onClick="saveAddDepartments"
              />
            </div>
          </div>
          <CustomComboboxVue
            data-test="facility-add-department-combobox"
            placeholder="Add Department"
            :posibleItems="unAssignedDepartments"
            @result="updateAddDepartmentsArray"
          />
        </div>
        <div
          v-else-if="departmentSection === 'createNewDepartment'"
          data-test="facility-create-department-section"
        >
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4>Departments</h4>
            <div class="d-flex align-items-center mb-3">
              <HSButton
                data-test="facility-create-department-button-cancel"
                label="Cancel"
                type="is-danger"
                @onClick="resetDepartmentMenu"
              />
            </div>
          </div>
          <form action="" @submit.prevent="createNewDepartment">
            <div class="d-flex flex-column mb-3">
              <label for="">Department Name</label>
              <input
                v-model="newDepartmentName"
                type="text"
                data-test="facility-create-department-input-name"
              />
            </div>
            <p
              v-show="formInvalid"
              class="has-warning"
              data-test="account-create-error"
            >
              You must fill all the fields in the form.
            </p>
            <HSButton
              data-test="facility-create-department-button-save-form"
              label="Create"
              type="is-success"
              :disabled="!newDepartmentName"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HSButton,
  HSDataTable,
  HSField,
} from "@healthscholars/healthscholars-vue";
import CustomComboboxVue from "../components/CustomCombobox.vue";
export default {
  name: "FacilityView",
  components: {
    HSButton,
    HSDataTable,
    CustomComboboxVue,
    HSField,
  },
  mounted() {
    this.$emit("facilityLoaded", {
      facilityId: this.$route.params.facilityId,
    });
  },
  data() {
    return {
      dataTableHeaders: [
        {
          label: "Name",
          key: "name",
          visible: true,
          searchable: true,
          sortable: true,
        },
      ],
      dataTableSearchItem: "name",
      dataTableSortBy: ["name"],
      isEditMode: false,
      departmentSection: "",
      formInvalid: false,
      oFacility: {},
      oFacilityRef: {},
      facilityFormKey: "facility-",
      addDepartmentsArray: [],
      newDepartmentName: "",
    };
  },
  computed: {
    getFacilityInfo() {
      return (
        this.$store.getters.getFacilityInfo(this.$route.params.facilityId) || {}
      );
    },
    getDepartmentFacilities() {
      return this.$store.getters.getDepartmentFacilities(
        this.$route.params.facilityId
      );
    },
    unAssignedDepartments() {
      const unassigned_departments = this.$store.state.unassigned_departments;
      if (unassigned_departments.length === 0) {
        return [];
      }
      return (
        this.$store.state.unassigned_departments.map((department) => {
          if (!department || !department.name) return;
          return {
            value: department.name,
            id: department.id,
          };
        }) || []
      );
    },
  },
  methods: {
    removeDepartmentFromFacility(departmentId) {
      this.$store.dispatch("removeFacilityDepartment", {
        departmentId,
        facilityId: this.$route.params.facilityId,
      });
    },
    enterEditMode() {
      this.isEditMode = true;
    },
    cancelEditFacility() {
      this.oFacility = { ...this.oFacilityRef };
      this.facilityFormKey += Math.floor(Math.random() * 9);
      this.isEditMode = false;
      this.formInvalid = false;
    },
    saveEditFacility() {
      const { name, address, city, postal_code, state_province } =
        this.oFacility;
      if (
        !name ||
        !address ||
        !city ||
        !state_province ||
        !postal_code ||
        state_province.length > 2
      ) {
        this.formInvalid = true;
        return;
      }
      const newFacility = {
        id: this.oFacility.id,
        name,
        address,
        city,
        postal_code,
        state_province,
      };
      this.$store.dispatch("updateFacility", {
        ...newFacility,
      });
      this.isEditMode = false;
    },
    deleteFacility() {
      this.$store.dispatch("softDeleteFacility", this.$route.params.facilityId);
      this.$router.go(-1);
    },
    setDepartmentSection(section) {
      this.departmentSection = section;
    },
    updateAddDepartmentsArray(items) {
      this.addDepartmentsArray = [...items];
    },
    resetDepartmentMenu() {
      this.departmentSection = "";
      this.newDepartmentName = "";
      this.addDepartmentsArray = [];
    },
    saveAddDepartments() {
      const departments = this.addDepartmentsArray.map((department) => {
        return {
          department_id: department.id,
          institution_id: this.getFacilityInfo.id,
        };
      });
      this.$store.dispatch("addDepartments", {
        departments,
        facilityId: this.$route.params.facilityId,
      });
      this.resetDepartmentMenu();
    },
    createNewDepartment() {
      this.$store.dispatch("createNewDepartment", {
        name: this.newDepartmentName,
        facilityId: this.$route.params.facilityId,
      });
      this.newDepartmentName = "";
    },
  },
  watch: {
    getFacilityInfo(facility) {
      this.oFacility = { ...facility };
      this.oFacilityRef = { ...facility };
    },
  },
};
</script>

<style lang="sass" scoped>
.facility-view
  .salesforce-facility-form
    .hs-field
      ::v-deep
        input
          &:hover
            cursor: default
          &:active, &:focus
            border: 2px solid #d3d3d3 !important
.csm-data-table
  .item-button
    ::v-deep
      .hs-button__content
        .icon
          font-size: 15px
          font-weight: 600
          color: black !important
</style>
