const randomString = () => {
  let string = "";
  const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  let counter = 0;
  while (counter < 4) {
    string += characters.charAt(Math.floor(Math.random() * characters.length));
    counter += 1;
  }
  return string;
};

const generateAccountCode = () => {
  let middleCharacter = ".";
  if (Math.floor(Math.random() * 10) % 2 === 0) middleCharacter = "@";
  const code = randomString() + middleCharacter + randomString();
  return code;
};

export default generateAccountCode;
