import Vue from "vue";
import router from "./router";
import apollo from "./apolloClient";
import store from "./store";
import App from "./App.vue";
import { Auth0Plugin } from "./auth";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/vue";

const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const environment = process.env.VUE_APP_ENVIRONMENT || "dev";

Vue.config.productionTip = false;
Vue.prototype.$apollo = apollo;

Vue.use(Auth0Plugin, {
  domain,
  client_id: clientId,
  connection: process.env.VUE_APP_AUTH0_CONNECTION,
  onRedirectCallback: () => {},
});

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: process.env.VUE_APP_ENV === "develop" ? 0.02 : 0.05,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: process.env.VUE_APP_ENV === "develop" ? 0.2 : 0.5,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        "localhost",
        "customer-success-manager-dev.healthscholars.com",
        "customer-success-manager.healthscholars.com",
        /^\//,
      ],
    }),
    new Sentry.Replay(),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  environment,
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
