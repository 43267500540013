<template>
  <div class="p-4" id="account-screen">
    <h2 class="w-100 pl-4" data-test="salesforce-account-name">
      {{ salesforceAccount.name }}
    </h2>

    <div class="flex-row d-flex justify-content-around">
      <div class="w-50">
        <HSDataTable
          class="w-100"
          dataTest="salesforce-account-courses"
          title="Courses"
          :itemsPerPage="5"
          :headers="dataTableHeaders"
          :items="salesforceAccountCourses"
          :sortBy="dataTableSortBy"
        >
          <template #name="{ item }">
            <span data-test="salesforce-account-course">{{ item.name }}</span>
          </template>
        </HSDataTable>
        <HSDataTable
          class="w-100"
          dataTest="proto-account-courses"
          title="Proto Courses"
          :itemsPerPage="5"
          :headers="dataTableHeadersProto"
          :items="protoAccountCourses"
          :sortBy="dataTableSortByProto"
        />
      </div>
      <div class="w-50 p-4">
        <div
          v-if="displayedSection == ''"
          data-test="salesforce-account-facilities-container"
        >
          <div class="d-flex flex-column">
            <HSButton
              class="mb-3"
              data-test="salesforce-account-edit-products-button"
              label="Edit Products"
              variant="is-light"
              @onClick="handleEditProducts"
            />
            <HSButton
              class="mb-3"
              label="Edit Proto Courses"
              variant="is-light"
              @onClick="handleEditCourses"
            />
            <HSButton
              class="mb-3"
              data-test="salesforce-account-create-new-facility-button"
              label="Create New Facility"
              variant="is-light"
              @onClick="showSection('createFacility')"
            />
            <HSButton
              class="mb-3"
              data-test="salesforce-account-add-facilities-button"
              label="+ Add Facilities"
              variant="is-light"
              @onClick="showSection('addFacility')"
            />
          </div>

          <HSDataTable
            class="csm-data-table"
            dataTest="salesforce-account-facilities"
            title="Facilities"
            :itemsPerPage="10"
            :headers="dataTableHeaders"
            :items="salesforceAccountFacilities"
            :sortBy="dataTableSortBy"
          >
            <template #name="{ item }">
              <div class="d-flex">
                <HSButton
                  class="mr-3 item-button"
                  data-test="delete-facility-btn"
                  icon="close"
                  color="black"
                  variant="is-light"
                  @onClick="removeFacilityFromAccount(item.institution_id)"
                />

                <router-link
                  :to="`/facility/${item.institution_id}`"
                  data-test="salesforce-account-facility"
                >
                  {{ item.name }}
                </router-link>
              </div>
            </template>
          </HSDataTable>
        </div>
        <div
          v-else-if="displayedSection == 'addFacility'"
          data-test="salesforce-account-add-facilities-section"
        >
          <div
            class="d-flex justify-content-between align-items-center mb-3"
            data-test="salesforce-account-add-facilities"
          >
            <h4>Facilities</h4>
            <div class="d-flex align-items-center mb-3">
              <HSButton
                data-test="salesforce-account-cancel-add-facilities"
                class="mr-3"
                type="is-danger"
                label="Cancel"
                @onClick="cancelAddFacilities"
              />
              <HSButton
                v-if="addfacilitiesArray.length"
                data-test="salesforce-account-save-add-facilities"
                type="is-success"
                label="Save"
                @onClick="saveAddFacilities"
              />
            </div>
          </div>
          <CustomComboboxVue
            data-test="salesforce-account-add-facilities-combobox"
            placeholder="Add facilities"
            :posibleItems="unAssignedFacilities"
            @result="updateAddFacilitiesArray"
          />
        </div>
        <div
          v-else-if="displayedSection == 'createFacility'"
          data-test="salesforce-account-create-facility-section"
        >
          <div
            class="d-flex justify-content-between align-items-center mb-3"
            data-test="salesforce-account-create-facility"
          >
            <h4>Facilities</h4>
            <div class="d-flex align-items-center mb-3">
              <HSButton
                data-test="salesforce-account-cancel-create-facility"
                label="Cancel"
                type="is-danger"
                @onClick="cancelCreateFacility"
              />
            </div>
          </div>

          <form action="" @submit.prevent="createNewFacility">
            <HSField
              class="w-100"
              data-test="account-create-name"
              label="Facility Name"
              inputType="text"
              variant="outlined"
              v-model="newFacility.name"
            />
            <HSField
              class="w-100"
              data-test="account-create-address"
              label="Address"
              inputType="text"
              variant="outlined"
              v-model="newFacility.address"
            />

            <div class="d-flex">
              <HSField
                data-test="account-create-city"
                label="City"
                inputType="text"
                variant="outlined"
                v-model="newFacility.city"
              />
              <HSField
                data-test="account-create-state"
                label="State"
                inputType="text"
                variant="outlined"
                v-model="newFacility.state"
              />
            </div>
            <HSField
              data-test="account-create-pc"
              label="Postal Code"
              inputType="text"
              variant="outlined"
              v-model="newFacility.pc"
            />

            <p
              v-show="formInvalid"
              class="has-warning"
              data-test="account-create-error"
            >
              You must fill all the fields in the form.
            </p>

            <HSButton
              label="Create"
              :disabled="
                !newFacility.name ||
                !newFacility.address ||
                !newFacility.state ||
                !newFacility.city ||
                !newFacility.pc ||
                newFacility.state > 2
              "
              data-test="account-create-button"
              type="is-success"
            />
          </form>
        </div>
        <div
          v-else-if="displayedSection == 'edit'"
          data-test="salesforce-account-edit-products-section"
        >
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4>Products</h4>
            <div class="d-flex align-items-center mb-3">
              <HSButton
                data-test="salesforce-account-cancel-edit-products"
                class="mr-3"
                type="is-danger"
                label="Cancel"
                @onClick="cancelEditProducts"
              />
              <HSButton
                v-if="addedProductsArray.length"
                dataTest="salesforce-account-save-edit-products"
                type="is-success"
                label="Save"
                @onClick="saveEditProducts"
              />
            </div>
          </div>
          <CustomComboboxVue
            data-test="salesforce-account-edit-products-combobox"
            placeholder="Edit products"
            :posibleItems="editProductsArray"
            :initialSelectedItems="salesforceAccountProducts()"
            @result="updateEditProductsArray"
          />
        </div>
        <div v-else-if="(displayedSection = 'editProto')">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4>Proto Courses</h4>
            <div class="d-flex align-items-center mb-3">
              <HSButton
                data-test="salesforce-account-cancel-edit-proto"
                class="mr-3"
                type="is-danger"
                label="Cancel"
                @onClick="showSection('')"
              />
              <HSButton
                v-if="addedCoursesArray.length"
                dataTest="salesforce-account-save-edit-proto"
                type="is-success"
                label="Save"
                @onClick="saveEditCourses"
              />
            </div>
          </div>
          <CustomComboboxVue
            data-test="salesforce-account-edit-proto-combobox"
            placeholder="Edit proto courses"
            :posibleItems="editCoursesArray"
            :initialSelectedItems="protoCourses()"
            @result="updateEditCoursesArray"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  HSDataTable,
  HSButton,
  HSField,
} from "@healthscholars/healthscholars-vue";
import CustomComboboxVue from "../components/CustomCombobox.vue";

export default {
  components: {
    HSDataTable,
    HSButton,
    CustomComboboxVue,
    HSField,
  },
  name: "AccountView",
  mounted() {
    this.$emit("accountLoaded", { accountId: this.$route.params.accountId });
    this.$store.dispatch(
      "loadProtoCoursesByAccount",
      this.$route.params.accountId
    );
  },
  data() {
    return {
      isModalOpen: true,
      dataTableHeaders: [
        {
          label: "Name",
          key: "name",
          visible: true,
          searchable: true,
          sortable: true,
        },
      ],
      dataTableHeadersProto: [
        {
          label: "Name",
          key: "title",
          visible: true,
          searchable: true,
          sortable: true,
        },
      ],
      dataTableSortBy: ["name"],
      dataTableSortByProto: ["title"],
      displayedSection: "",
      addedProductsArray: [],
      addfacilitiesArray: [],
      newFacility: {},
      formInvalid: false,
      addedCoursesArray: [],
    };
  },
  computed: {
    salesforceAccount() {
      return (
        this.$store.state.sf_accounts.find(
          (account) => account.account_id == this.$route.params.accountId
        ) || ""
      );
    },

    salesforceAccountCourses() {
      return (
        this.$store.state.sf_courses_by_account_id[
          this.$route.params.accountId
        ] || []
      );
    },

    protoAccountCourses() {
      return (
        this.$store.state.proto_by_account_id[this.$route.params.accountId] ||
        []
      );
    },

    salesforceAccountFacilities() {
      return (
        this.$store.state.sf_facilities_by_account_id[
          this.$route.params.accountId
        ] || []
      );
    },

    unAssignedFacilities() {
      return (
        this.$store.state.unassigned_facilities.map((facility) => {
          return {
            value: facility.name,
            id: facility.institution_id,
          };
        }) || []
      );
    },

    account_id() {
      return this.$route ? this.$route.params.accountId : "";
    },
    editProductsArray() {
      const products = this.$store.state.sf_products;
      if (!products) return [];
      return products.map((product) => {
        return {
          value: product.name,
          id: product.product_id,
        };
      });
    },
    editCoursesArray() {
      const courses = this.$store.state.proto_courses;
      if (!courses) return [];
      return courses.map((course) => {
        return {
          value: course.title,
          id: course.id,
        };
      });
    },
  },
  methods: {
    removeFacilityFromAccount(institutionId) {
      this.$store.dispatch("removeAccountFacility", {
        institutionId,
        accountId: this.$route.params.accountId,
      });
    },
    updateAddFacilitiesArray(items) {
      this.addfacilitiesArray = [...items];
    },
    cancelAddFacilities() {
      this.displayedSection = "";
      this.addfacilitiesArray = [];
    },
    saveAddFacilities() {
      const facilities = this.addfacilitiesArray.map((facility) => {
        return {
          institution_id: facility.id,
          account_id: this.salesforceAccount.account_id,
        };
      });
      this.$store.dispatch("addFacilities", {
        facilities,
        accountId: this.salesforceAccount.account_id,
      });
      this.displayedSection = "";
      this.addfacilitiesArray = [];
    },
    showSection(section) {
      this.displayedSection = section;
    },
    createNewFacility() {
      const { name, address, city, state, pc } = this.newFacility;
      if (!name || !address || !city || !state || !pc) {
        this.formInvalid = true;
        return;
      }

      axios
        .post(process.env.VUE_APP_SF_API + "/create/facility", {
          name: name,
          address: address,
          city: city,
          state_province: state,
          postal_code: pc,
          account_id: this.$route.params.accountId,
          latitude: 0,
          longitude: 0,
          timezone: "America/Denver",
          new_calendar: 0,
        })
        .then(() => {
          this.$store.dispatch(
            "loadSalesforceAccountFacilities",
            this.$route.params.accountId
          );
          this.newFacility = {};
          this.formInvalid = false;
          alert("You created a new facility.");
        })
        .catch(() => {
          alert(
            "There was an error when creating the facility. Try again later."
          );
        });
    },
    cancelCreateFacility() {
      this.displayedSection = "";
      this.newFacility = {};
      this.formInvalid = false;
    },
    deleteFacility(institution_id) {
      axios
        .post(process.env.VUE_APP_SF_API + "/update/deletefacility", {
          institutionid: institution_id,
        })
        .then(() => {
          this.$store.commit("deleteFacility", {
            institution_id,
          });
          alert("You deleted a facility.");
        })
        .catch(() => {
          alert(
            "There was an error when deleting the facility. Try again later."
          );
        });
    },
    handleEditProducts() {
      this.displayedSection = "edit";
      this.$store.dispatch("loadSalesforceProducts");
      this.addedProductsArray = [...this.salesforceAccountProducts()];
    },
    handleEditCourses() {
      this.displayedSection = "editProto";
      this.$store.dispatch("loadProtoCourses");
      this.addedCoursesArray = [...this.protoCourses()];
    },
    salesforceAccountProducts() {
      const products =
        this.$store.state.sf_products_by_account_id[
          this.$route.params.accountId
        ];
      if (!products) return [];
      return products.map((product) => {
        return {
          value: product.name,
          id: product.product_id,
        };
      });
    },
    protoCourses() {
      const courses =
        this.$store.state.proto_by_account_id[this.$route.params.accountId];
      if (!courses) return [];
      return courses.map((course) => {
        return {
          value: course.title,
          id: course.id,
        };
      });
    },
    updateEditProductsArray(items) {
      this.addedProductsArray = [...items];
    },
    updateEditCoursesArray(items) {
      this.addedCoursesArray = [...items];
    },
    cancelEditProducts() {
      this.displayedSection = "";
      this.addedProductsArray = [];
    },
    saveEditProducts() {
      const products = this.addedProductsArray.map((product) => {
        return {
          product_id: product.id,
          account_id: this.salesforceAccount.account_id,
        };
      });
      this.$store.dispatch("addProducts", {
        products,
        accountId: this.salesforceAccount.account_id,
      });
      this.displayedSection = "";
      this.addedProductsArray = [];
    },
    saveEditCourses() {
      const courses = this.addedCoursesArray.map((course) => {
        return {
          course_id: course.id,
          account_id: this.salesforceAccount.account_id,
        };
      });
      this.$store.dispatch("addCourses", {
        courses,
        accountId: this.salesforceAccount.account_id,
      });
      this.displayedSection = "";
      this.addedCoursesArray = [];
    },
  },
};
</script>

<style scoped lang="sass">
.csm-data-table
  margin-top: 10px
  h2
    font-size: 1.5em
  th
    text-align: center
.item-button
  ::v-deep
    .hs-button__content
      .icon
        font-size: 15px
        font-weight: 600
        color: black !important
</style>
