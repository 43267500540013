import { getInstance } from "./index";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      if (to.name === "home") {
        return next({ name: "accounts" });
      }
      return next();
    }
    authService.loginWithRedirect({
      appState: { targetUrl: to.fullPath },
    });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};

export const homeGuard = (_to, _from, next) => {
  const authService = getInstance();
  const fn = () => {
    if (authService.isAuthenticated) {
      return next({ name: "accounts" });
    }
    return next();
  };
  if (!authService.loading) {
    return fn();
  }
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
