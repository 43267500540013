import ApolloClient from "apollo-boost";
import store from "./store";

export default new ApolloClient({
  uri: "//hasura.healthscholars.com/v1/graphql",
  headers: {
    "x-hasura-admin-secret": process.env.VUE_APP_HASURA_ADMIN_SECRET,
  },
  onError: (err) => {
    if (err.networkError) {
      if (err.networkError.message) {
        store.dispatch("createAlert", {
          message:
            "The connection is unstable at this time, please try agian later",
          type: "is-warning",
        });
      }
    }
    if (err.graphQLErrors) {
      err.graphQLErrors.forEach(({ message }) =>
        store.dispatch("createAlert", {
          message: `Error: ${message}`,
          type: "is-danger",
        })
      );
    }
  },
});
