import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import AccountView from "../views/AccountView.vue";
import AccountsView from "../views/AccountsView.vue";
import FacilityView from "../views/FacilityView.vue";
import DepartmentView from "../views/DepartmentView.vue";
import ProductsView from "../views/ProductsView.vue";
import ProductView from "../views/ProductView.vue";
import { authGuard, homeGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: homeGuard,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsView,
    beforeEnter: authGuard,
  },
  {
    path: "/products/:productId",
    name: "product",
    component: ProductView,
    beforeEnter: authGuard,
  },
  {
    path: "/accounts",
    name: "accounts",
    component: AccountsView,
    beforeEnter: authGuard,
  },
  {
    path: "/accounts/:accountId",
    name: "account",
    component: AccountView,
  },
  {
    path: "/facility/:facilityId",
    name: "facility",
    component: FacilityView,
  },
  {
    path: "/department/:departmentId",
    name: "department",
    component: DepartmentView,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
