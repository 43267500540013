import gql from "graphql-tag";
import apollo from "./apolloClient";
import { uniqBy } from "lodash";
import axios from "axios";
import generateAccountCode from "./helpers/generateAccountCode";

const suffixEnv = process.env.VUE_APP_ENV === "develop" ? "_test" : "";

const Api = {
  async getSalesforceAccounts() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSalesforceAccounts {
          sf_accounts${suffixEnv} {
            id
            name
            account_id
            code
          }
        }
      `,
    });
    return response.data[`sf_accounts${suffixEnv}`];
  },

  async getSalesforceAccount(accountId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSalesforceAccount {
          sf_accounts${suffixEnv}(where: {account_id: {_eq: "${accountId}"}}) {
            account_id
            id
            name
          }
        }
      `,
    });
    return response.data[`sf_accounts${suffixEnv}`][0];
  },

  async getSFProducts() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSFProducts {
          sf_products${suffixEnv} {
            name
            product_id
            product_courses {
              course {
                name
                app_id
                course_package_id
                id
              }
            }
          }
        }
      `,
    });
    return response.data[`sf_products${suffixEnv}`];
  },

  async getSFCourses() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSFCourses {
          courses${suffixEnv} {
            name
            app_id
            course_package_id
            id
          }
        }
      `,
    });
    return response.data[`courses${suffixEnv}`];
  },

  async createSalesforceProduct(name, productId) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation CreateSalesforceProduct {
          insert_sf_products${suffixEnv}(objects: {name: "${name}", product_id: "${productId}"}) {
            returning {
              name
              product_id
            }
          }
        }
      `,
    });
    return response.data[`insert_sf_products${suffixEnv}`].returning[0];
  },

  async getSalesforceAccountProducts(accountId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSalesforceAccountProducts {
          sf_products${suffixEnv}(where: {sf_accounts_products: {account_id: {_eq: "${accountId}"}}}) {
              name
              product_id
          }
        }
      `,
    });
    return response.data[`sf_products${suffixEnv}`];
  },
  async addProducts(productsArray, accountId) {
    await apollo.mutate({
      mutation: gql`
        mutation DeleteProducts {
          delete_sf_accounts_products${suffixEnv}(where: {account_id: {_eq: "${accountId}"}}) {
            affected_rows
          }
        }
      `,
    });
    const response = await apollo.mutate({
      mutation: gql`
        mutation AddProducts {
          insert_sf_accounts_products${suffixEnv}(objects: [${productsArray.map(
        (item) => `{
              account_id: "${accountId}", product_id: "${item.product_id}" }`
      )}]) {
            returning {
              sf_product {
                name
                product_id
              }
            }
          }
        }
      `,
    });
    return response.data[
      `insert_sf_accounts_products${suffixEnv}`
    ].returning.map((item) => item.sf_product);
  },
  async addProtoCourses(coursesArray, accountId) {
    await apollo.mutate({
      mutation: gql`
        mutation DeleteProtoCourses {
          delete_account_course_metadata${suffixEnv}(where: {account_id: {_eq: "${accountId}"}}) {
            affected_rows
          }
        }
      `,
    });
    const response = await apollo.mutate({
      mutation: gql`
        mutation AddProtoCourses {
          insert_account_course_metadata${suffixEnv}(objects: [${coursesArray.map(
        (item) => `{
              account_id: "${accountId}", course_metadata_id: "${item.course_id}" }`
      )}]) {
            returning {
              course_metadata {
                title
                id
              }
            }
          }
        }
      `,
    });
    return response.data[
      `insert_account_course_metadata${suffixEnv}`
    ].returning.map((item) => item.course_metadata);
  },
  async addCourseProduct(coursesProductsArray, productId) {
    await apollo.mutate({
      mutation: gql`
        mutation DeleteCourseProduct {
          delete_product_courses${suffixEnv}(where: {product_id: {_eq: "${productId}"}}) {
            affected_rows
          }
        }
      `,
    });
    const response = await apollo.mutate({
      mutation: gql`
        mutation AddCourseProduct {
          insert_product_courses${suffixEnv}(objects: [${coursesProductsArray.map(
        (item) => `{
              product_id: "${productId}", course_package_id: "${item.course_id}" }`
      )}]) {
            returning {
              course {
                name
              }
            }
          }
        }
      `,
    });
    return response.data[`insert_product_courses${suffixEnv}`].returning.map(
      (item) => item.course
    );
  },

  async getSalesforceAccountCourses(accountId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query getSalesforceAccountCourses {
          sf_accounts_products${suffixEnv}(where: {sf_account: {account_id: {_eq: "${accountId}"}}}) {
            sf_product {
              name
              product_id
            }
          }
        }
      `,
    });

    let products_ids = response.data[`sf_accounts_products${suffixEnv}`].map(
      (item) => item.sf_product.product_id
    );

    const coursesResponse = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSalesforceAccountCourses {
          product_courses${suffixEnv}(where: {product_id: {_in: [${products_ids.map(
        (id) => '"' + id + '",'
      )}]}}, order_by: {course: {name: asc}}) {
            course {
              name
            }
          }
        }
      `,
    });

    return uniqBy(
      coursesResponse.data[`product_courses${suffixEnv}`].map(
        (item) => item.course
      ),
      "name"
    );
  },

  async getSalesforceAccountFacilities(accountId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSalesforceAccountFacilities {
          account_facilities${suffixEnv}(where: {account_id: {_eq: "${accountId}"}}) {
            facility {
              institution_id
              name
              id
            }
          }
        }
      `,
    });
    return response.data[`account_facilities${suffixEnv}`].map(
      (item) => item.facility
    );
  },

  async getAllFacilities() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetAllFacilities {
          facilities${suffixEnv}(where: {deleted_at: {_is_null: true}}) {
            institution_id
            name
            id
          }
        }
      `,
    });
    return response.data[`facilities${suffixEnv}`];
  },

  async getAssignedFacilities() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetSalesforceAccountFacilities {
          account_facilities${suffixEnv} {
            facility {
              institution_id
            }
          }
        }
      `,
    });
    return response.data[`account_facilities${suffixEnv}`].map(
      (item) => item.facility
    );
  },

  async addFacilities(facilitiesArray) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation AddFacilities {
          insert_account_facilities${suffixEnv}(objects: [${facilitiesArray.map(
        (item) => `{
              account_id: "${item.account_id}", institution_id: "${item.institution_id}" }`
      )}]) {
            returning {
              facility {
                institution_id
                name
              }
            }
          }
        }
      `,
    });
    return response.data[`insert_account_facilities${suffixEnv}`].returning.map(
      (item) => item.facility
    );
  },

  async getFacility(facilityId) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_SF_API}/query/facility?institutionid=${facilityId}`
      );
      return response.data;
    } catch (e) {
      return {};
    }
  },

  async updateFacility(facility) {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_SF_API}/update/facility`,
        facility
      );
      await apollo.mutate({
        mutation: gql`
          mutation UpdateFacility {
            update_facilities${suffixEnv}(where: {institution_id: {_eq: "${facility.id}"}}, _set: {name: "${facility.name}"}) {
              returning {
                institution_id
                name
              }
            }
          }
        `,
      });
      return response.data;
    } catch (e) {
      return {};
    }
  },

  async removeAccountFacility(institutionId) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation MyMutation {
          delete_account_facilities${suffixEnv}(where: {facility: {institution_id: {_eq: ${institutionId}}}}) {
            returning {
              facility {
                id
                name
                institution_id
              }
              account_id
            }
          }
        }
      `,
    });

    return response.data[`delete_account_facilities${suffixEnv}`].returning[0];
  },

  async removeFacilityDepartment({ departmentId, facilityId, token }) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation DeleteDepartmentFacilityAssociation {
          delete_v2_departments${suffixEnv}(
            where: {
              _and: [
                { institution_id: { _eq: ${facilityId} } }
                { department_label_id: { _eq: ${departmentId} } }
              ]
            }
          ) {
            returning {
              id
              v2_department_label {
                id
                name
              }
            }
          }
        }
      `,
    });
    if (!response.data[`delete_v2_departments${suffixEnv}`].returning[0])
      return;

    await axios.post(
      `${process.env.VUE_APP_SF_API}/update/disassociate_department_facility`,
      {
        postgresId:
          response.data[`delete_v2_departments${suffixEnv}`].returning[0].id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.data[`delete_v2_departments${suffixEnv}`].returning[0]
      .v2_department_label;
  },

  async softDeleteFacility(institutionId) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation MyMutation {
          update_facilities${suffixEnv}(where: {institution_id: {_eq: ${institutionId}}}, _set: {deleted_at: "NOW()"}) {
            returning {
              institution_id
              deleted_at
            }
          }
        }
      `,
    });

    return response.data[`update_facilities${suffixEnv}`].returning;
  },

  async getDepartmentsByFacility(institutionId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query V2GetDepartmentsByFacility {
          v2_departments${suffixEnv}(where: {institution_id: {_eq: ${institutionId}}}) {
            v2_department_label {
              id
              name
            }
        }
      }
      `,
    });
    return response.data[`v2_departments${suffixEnv}`].map(
      (item) => item.v2_department_label
    );
  },

  async getAllDepartments() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetAllDepartments {
          departments${suffixEnv}(where: {deleted_at: {_is_null: true}}) {
            id
            name
          }
        }
      `,
    });
    return response.data[`departments${suffixEnv}`];
  },

  async getAssignedDepartments() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetAssignedDepartments {
          facilities_departments${suffixEnv} {
            department {
              id
            }
          }
        }
      `,
    });
    return response.data[`facilities_departments${suffixEnv}`].map(
      (item) => item.department
    );
  },

  async addDepartments(departmentsArray, token) {
    const objects = departmentsArray.map(
      (department) => `{
      department_label_id: ${department.department_id},
      institution_id: ${department.institution_id},
    }`
    );
    const response = await apollo.mutate({
      mutation: gql`
        mutation CreateDepartmentFacilityAssociation {
          insert_v2_departments${suffixEnv}(
            objects: [${objects.join("")}]
          ) {
            returning {
              id
              v2_department_label {
                id
                name
              }
            }
          }
        }
      `,
    });
    for (const index in departmentsArray) {
      const department = departmentsArray[index];
      const id =
        response.data[`insert_v2_departments${suffixEnv}`].returning[index].id;
      await axios.post(
        `${process.env.VUE_APP_SF_API}/create/associate_department`,
        {
          institutionId: department.institution_id,
          departmentLabelPostgresId:
            response.data[`insert_v2_departments${suffixEnv}`].returning[index]
              .v2_department_label.id,
          postgresId: id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
    }
    return response.data[`insert_v2_departments${suffixEnv}`].returning.reduce(
      (addedDepartments, department) => {
        addedDepartments.push({
          id: department.v2_department_label.id,
          name: department.v2_department_label.name,
        });
        return addedDepartments;
      },
      []
    );
  },

  async createNewDepartment(name, facilityId, token) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation V2CreateDepartment {
          insert_v2_department_labels${suffixEnv}_one(object: {
            institution_id: ${facilityId}
            name: "${name}"
            v2_departments: {data: {
              institution_id: ${facilityId}
            }}
          }) {
            id
            name
            v2_departments {
              id
            }
          }
        }
      `,
    });
    const data = response.data[`insert_v2_department_labels${suffixEnv}_one`];
    await axios.post(
      `${process.env.VUE_APP_SF_API}/create/departments`,
      {
        name,
        institutionId: facilityId,
        postgresDepartmentLabelId: data.id,
        postgresDepartmentId: data.v2_departments[0].id,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return response.data[`insert_v2_department_labels${suffixEnv}_one`];
  },

  async getDepartment(departmentId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetDepartment {
          v2_department_labels${suffixEnv}(where: {id: {_eq: ${departmentId}}}) {
            id
            name
          }
        }
      `,
    });
    if (!response.data[`v2_department_labels${suffixEnv}`].length > 0) {
      return {};
    }
    return response.data[`v2_department_labels${suffixEnv}`][0];
  },

  async updateDepartment(department) {
    const response = await apollo.mutate({
      mutation: gql`
        mutation UpdateDepartmentById {
          update_v2_department_labels${suffixEnv}_by_pk(pk_columns: {id: ${department.id}}, _set: {name: "${department.name}"}) {
            id
            name
          }
        }
      `,
    });

    const data = response.data[`update_v2_department_labels${suffixEnv}_by_pk`];
    await axios.patch(`${process.env.VUE_APP_SF_API}/update/department_label`, {
      postgresDepartmentLabelId: data.id,
      name: data.name,
    });
    return data;
  },

  async getUnassignedDepartments({ facilityId }) {
    const response = await apollo.query({
      query: gql`
        query LoadDepartmentsAvailableForFacility {
          v2_department_labels${suffixEnv}(
            where: {
              _or: [
                { institution_id: { _is_null: true } }
                { institution_id: { _eq: ${facilityId} } }
              ]
            }
          ) {
            id
            name
          }
          v2_departments${suffixEnv}(where: {institution_id: {_eq: ${facilityId}}}) {
            department_label_id
          }
        }
      `,
    });

    return response.data[`v2_department_labels${suffixEnv}`].filter(
      (departmentLabel) => {
        return !response.data[`v2_departments${suffixEnv}`].find(
          (department) => {
            return department.department_label_id == departmentLabel.id;
          }
        );
      }
    );
  },

  async updateAccountCode(account) {
    const code = generateAccountCode();
    try {
      const response = await apollo.mutate({
        mutation: gql`
        mutation UpdateSfAccountCode {
          update_sf_accounts${suffixEnv}(
            where: { account_id: { _eq: "${account.account_id}" } }
            _set: { code: "${code}" }
          ) {
            returning {
              code
            }
          }
        }
      `,
      });

      const data =
        response.data[`update_sf_accounts${suffixEnv}`].returning[0].code;
      return data;
    } catch (error) {
      if (
        error ===
        'Error: GraphQL error: Uniqueness violation. duplicate key value violates unique constraint "sf_accounts_code_key"'
      ) {
        const response = this.updateAccountCode();
        return response;
      }
    }
  },

  async getProtoCourses() {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetProtoCourses {
          course_metadata${suffixEnv}(where: {status: {_eq: "Release"}}, distinct_on: course_id) {
            id
            title
          }
        }
      `,
    });
    return response.data[`course_metadata${suffixEnv}`];
  },

  async getProtoCoursesByAccount(accountId) {
    const response = await apollo.query({
      fetchPolicy: "no-cache",
      query: gql`
        query GetProtoCoursesByAccount {
          account_course_metadata${suffixEnv}(where: { account_id: { _eq: "${accountId}" } }) {
            course_metadata {
              title
              id
            }
          }
        }
      `,
    });
    return response.data[`account_course_metadata${suffixEnv}`].map(
      (item) => item.course_metadata
    );
  },
};

export default Api;
