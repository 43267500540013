<template>
  <div class="mt-3" v-if="!product || !product.name">
    <HSSkeleton width="280px" height="30px" />
    <div class="mt-2 d-flex">
      <HSSkeleton width="80px" height="30px" />
      <HSSkeleton class="ml-3" width="150px" height="30px" />
    </div>
    <HSSkeleton class="mt-2" width="100%" height="500px" />
  </div>
  <div
    v-else
    class="flex-row d-flex justify-content-around p-3"
    id="product-screen"
  >
    <div class="w-50">
      <h2 class="w-100 pl-4" data-test="product-name">
        {{ product.name }}
      </h2>
      <HSDataTable
        class="p-3"
        dataTest="product-courses"
        title="Courses"
        :itemsPerPage="20"
        :items="courses"
        :headers="dataTableHeaders"
      />
    </div>
    <div class="w-50 p-4">
      <HSButton
        v-if="!addingCourse"
        class="mt-3 mb-3 w-100"
        data-test="add-course-button"
        @onClick="handleAddCourse"
        label="+ Add Courses"
      />
      <div v-else>
        <div
          class="d-flex justify-content-between align-items-center mb-3"
          data-test="add-course-header"
        >
          <h4>Courses</h4>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <HSButton
              data-test="add-course-cancel-button"
              label="Cancel"
              type="is-danger"
              class="mr-3"
              @onClick="cancelAddCourse"
            />
            <HSButton
              v-if="addCoursesArray.length"
              data-test="add-course-save-button"
              type="is-success"
              label="Save"
              @onClick="saveAddCourse"
            />
          </div>
        </div>
        <CustomComboboxVue
          data-test="add-course-combobox"
          placeholder="Add Course"
          :posibleItems="allCoursesArray"
          :initialSelectedItems="productCourses()"
          @result="updateAddCourseArray"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  HSDataTable,
  HSSkeleton,
  HSButton,
} from "@healthscholars/healthscholars-vue";
import CustomComboboxVue from "../components/CustomCombobox.vue";
export default {
  components: {
    HSDataTable,
    HSSkeleton,
    HSButton,
    CustomComboboxVue,
  },
  data() {
    return {
      addingCourse: false,
      addCoursesArray: [],
      dataTableHeaders: [
        {
          label: "Name",
          key: "name",
          searchable: true,
          visible: true,
          sortable: true,
        },
      ],
    };
  },
  name: "ProductView",
  mounted() {
    if (this.$store.state.sf_products.length <= 0) {
      this.$store.dispatch("loadSalesforceProducts");
    }
  },
  computed: {
    product() {
      return (
        this.$store.state.sf_products.find(
          (product) => product.product_id === this.$route.params.productId
        ) || {}
      );
    },
    courses() {
      if (!this.product.product_courses) return [];
      return this.product.product_courses.map(
        (product_course) => product_course.course
      );
    },
    allCoursesArray() {
      const courses = this.$store.state.sf_courses;
      if (!courses) return [];
      return courses.map((course) => {
        return {
          id: course.course_package_id,
          value: course.name,
        };
      });
    },
  },
  methods: {
    productCourses() {
      if (!this.product.product_courses) return [];
      return this.product.product_courses.map((product_course) => {
        return {
          id: product_course.course.course_package_id,
          value: product_course.course.name,
        };
      });
    },
    handleAddCourse() {
      this.$store.dispatch("loadSalesforceCourses");
      this.addingCourse = true;
      this.addCoursesArray = [...this.productCourses()];
    },
    cancelAddCourse() {
      this.addingCourse = false;
    },
    updateAddCourseArray(items) {
      this.addCoursesArray = items;
    },
    saveAddCourse() {
      const courses = this.addCoursesArray.map((course) => {
        return {
          product_id: this.product.product_id,
          course_id: course.id,
        };
      });
      this.$store.dispatch("addCourseProduct", {
        courses,
        product_id: this.product.product_id,
      });
      this.addingCourse = false;
    },
  },
};
</script>
