<template>
  <div class="home">
    <div class="banner-container">
      <img
        src="https://app.healthscholars.com/images/banner.png"
        alt="healthscholars-banner"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style lang="sass" scoped>
.banner-container
  border-radius: 0.938rem
  margin: 20px auto
  max-width: 1260px
  min-width: 240px
  padding-bottom: 3rem
  padding-left: 15px
  padding-right: 15px
</style>
