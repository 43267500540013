<template>
  <div class="table-container">
    <div class="mt-3" v-if="$store.state.sf_accounts <= 0">
      <HSSkeleton width="280px" height="30px" />
      <div class="mt-2 d-flex">
        <HSSkeleton width="80px" height="30px" />
        <HSSkeleton class="ml-3" width="150px" height="30px" />
      </div>
      <HSSkeleton class="mt-2" width="100%" height="500px" />
    </div>
    <HSDataTable
      v-else
      dataTest="salesforce-accounts-table"
      title="Salesforce Accounts"
      :itemsPerPage="20"
      :headers="dataTableHeaders"
      :items="$store.state.sf_accounts"
    >
      <template #name="{ item }">
        <router-link
          :to="'/accounts/' + item.account_id"
          data-test="salesforce-account-link"
        >
          {{ item.name }}
        </router-link>
      </template>
      <template #code="{ item }">
        <div
          class="account-code"
          data-test="account-code-container"
          @click="onEditAccountCode(item)"
        >
          <p>{{ item.code }}</p>
          <HSIcon class="edit-icon" icon="edit" size="20px"> Continue </HSIcon>
        </div>
      </template>
    </HSDataTable>
  </div>
</template>

<script>
import {
  HSDataTable,
  HSIcon,
  HSSkeleton,
} from "@healthscholars/healthscholars-vue";

export default {
  components: {
    HSDataTable,
    HSIcon,
    HSSkeleton,
  },
  data() {
    return {
      dataTableHeaders: [
        {
          label: "Name",
          key: "name",
          searchable: true,
          visible: true,
          sortable: true,
        },
        {
          label: "Account Code",
          key: "code",
          searchable: true,
          visible: true,
          sortable: true,
        },
      ],
    };
  },
  methods: {
    onEditAccountCode(account) {
      this.$store.dispatch("establishEditingAccountCode", account);
    },
  },
};
</script>

<style scoped lang="sass">
.table-container
  width: 80%
  margin: 20px auto

.account-code
  display: flex
  .edit-icon
    visibility: hidden
    margin-left: 0.75em
    font-size: 20px
  &:hover
    cursor: pointer
  &:hover > p
    text-decoration: underline
  &:hover > .edit-icon
    visibility: visible
</style>
