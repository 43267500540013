<template>
  <div id="department-view" class="department-view p-4">
    <h2 class="w-100 pl-4" data-test="department-name">
      {{ getDepartmentInfo.name }}
    </h2>
    <div class="department-view__container d-flex flex-column p-4">
      <HSButton
        v-if="!isEditMode"
        class="mb-3 edit-button"
        data-test="department-edit-button"
        label="Edit Department"
        loading
        @onClick="enterEditMode"
        variant="is-light"
      />
      <div v-else class="department-view__container--confirm-buttons mb-3">
        <HSButton
          class="cancel-button"
          data-test="department-button-cancel"
          type="is-danger"
          label="Cancel"
          @onClick="cancelEditDepartment"
        />
        <HSButton
          class="save-button"
          data-test="department-button-save"
          type="is-success"
          label="Save"
          @onClick="saveEditDepartment"
        />
      </div>
      <form @submit.prevent="saveEditDepartment" data-test="department-form">
        <div>
          <div class="d-flex flex-column">
            <HSField
              data-test="department-input-name"
              :key="departmentInputKey"
              inputType="text"
              label="Edit department"
              placeholder="Enter the department name"
              variant="outlined"
              :clearable="isEditMode"
              :readonly="!isEditMode"
              v-model="newDepartmentInfo.name"
            />
          </div>
          <!-- TODO: Add this warning to hint prop in field -->
          <p
            v-show="formInvalid"
            class="has-warning"
            data-test="account-create-error"
          >
            You must fill all the fields in the form.
          </p>
        </div>
      </form>
      <HSButton
        class="mb-3 return-to-facility-btn"
        data-test="return-to-facility"
        label="Return to facility detail"
        @onClick="goBack"
        variant="is-light"
      />
    </div>
  </div>
</template>

<script>
import {
  HSButton,
  HSField,
  // HSForm
} from "@healthscholars/healthscholars-vue";
export default {
  name: "DepartmentView",
  components: {
    HSButton,
    HSField,
    // TODO: Implement this!
    // HSForm,
  },
  mounted() {
    this.$emit("departmentLoaded", {
      departmentId: this.$route.params.departmentId,
    });
  },
  data() {
    return {
      isEditMode: false,
      formInvalid: false,
      newDepartmentInfo: {
        id: "",
        name: "",
      },
      departmentInputKey: "d-",
    };
  },
  computed: {
    getDepartmentInfo() {
      return (
        this.$store.getters.getDepartmentInfo(
          this.$route.params.departmentId
        ) || {
          id: "",
          name: "",
        }
      );
    },
  },
  methods: {
    enterEditMode() {
      this.isEditMode = true;
      this.newDepartmentInfo = {
        id: this.getDepartmentInfo.id,
        name: this.getDepartmentInfo.name,
      };
    },
    cancelEditDepartment() {
      this.isEditMode = false;
      this.formInvalid = false;
      this.newDepartmentInfo = {
        id: this.getDepartmentInfo.id,
        name: this.getDepartmentInfo.name,
      };
      this.departmentInputKey += Math.floor(Math.random() * 9);
    },
    saveEditDepartment() {
      if (!this.newDepartmentInfo.name) {
        this.formInvalid = true;
        return;
      }
      this.$store.dispatch("updateDepartment", {
        ...this.newDepartmentInfo,
      });
      this.isEditMode = false;
    },
    goBack() {
      window.history.go(-1);
    },
  },
  watch: {
    getDepartmentInfo(department) {
      this.newDepartmentInfo = { ...department };
    },
  },
};
</script>

<style lang="sass" scoped>
.department-view
  &__container
    .hs-field
      width: 40%
    .edit-button
      width: 140px
      margin-left: calc(40% - 140px)
    &--confirm-buttons
      width: 150px
      margin-left: calc(40% - 150px)
      .cancel-button,
      .save-button
        margin-left: 13px
    .return-to-facility-btn
      width: 20%
</style>
