<template>
  <div class="dropdown" ref="dropdown">
    <div class="dropdown__input-container" @click="openDropdown">
      <input
        class="dropdown__input"
        type="text"
        v-model="search"
        :placeholder="placeholder"
        :data-test="dataTest"
      />
      <span class="dropdown__icon" :class="isDropdownOpen && 'icon__active'">
        <HSIcon icon="arrow_drop_down" />
      </span>
    </div>
    <div class="dropdown__results" v-if="isDropdownOpen">
      <div
        class="dropdown__result"
        v-for="item in filteredItems"
        :key="item.id"
        @click="select(item)"
      >
        {{ item.value }}
      </div>
    </div>
  </div>
</template>

<script>
import { HSIcon } from "@healthscholars/healthscholars-vue";

export default {
  name: "hs-custom-select-input",
  components: {
    HSIcon,
  },
  mounted() {
    document
      .querySelector("body")
      .addEventListener("click", this.closeDropdown);
    this.$refs.dropdown.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  },
  beforeUnmount() {
    document
      .querySelector("body")
      .removeEventListener("click", this.closeDropdown);
  },
  computed: {
    filteredItems() {
      return this.posibleItems.filter((item) => {
        if (this.search === "") {
          return true;
        }
        return item.value.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  data() {
    return {
      search: "",
      isDropdownOpen: false,
      clickOutside: false,
    };
  },
  methods: {
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    openDropdown() {
      this.isDropdownOpen = true;
    },
    select(item) {
      this.$emit("select", item);
      if (this.cleanValue) {
        this.search = "";
      } else {
        this.search = item.value;
      }
      this.closeDropdown();
    },
  },
  props: {
    posibleItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Type...",
    },
    cleanValue: {
      type: Boolean,
      default: false,
    },
    dataTest: {
      type: String,
    },
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 36px;
}
.dropdown__input {
  width: 100%;
  height: 100%;
  padding: 0 26px 0 10px;
  border: none;
  outline: none;
  font-size: 1.2rem;
  color: #333;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}
.dropdown__icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-45%);
  color: #333;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.dropdown__icon.icon__active {
  transform: rotateX(180deg) translateY(55%);
}
.dropdown__results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  min-height: 36px;
  max-height: 216px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  overflow-y: auto;
}
.dropdown__result {
  padding: 10px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.dropdown__result:hover {
  background-color: #f5f5f5;
}
</style>
