<template>
  <div class="combobox">
    <HSSelect
      :posibleItems="getUnselectedItems"
      :placeholder="placeholder"
      @select="getBox"
      cleanValue
    />
    <div class="combobox__items__list">
      <div
        class="combobox__item"
        v-for="item in selectedOptions"
        :key="item.id"
      >
        {{ item.value }}
        <span class="combobox__item__icon" @click="unSelectItem(item)">
          <HSIcon icon="cancel" size="20px" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import HSSelect from "./CustomSelectInput.vue";
import { HSIcon } from "@healthscholars/healthscholars-vue";

export default {
  name: "hs-custom-combobox",
  components: {
    HSSelect,
    HSIcon,
  },
  computed: {
    getUnselectedItems() {
      return this.posibleItems.filter((item) => {
        return !this.selectedOptions.includes(item);
      });
    },
  },
  data() {
    return {
      selectedOptions: [...this.initialSelectedItems],
    };
  },
  methods: {
    getBox(item) {
      //if item is already selected, do nothing
      if (
        this.selectedOptions.find((selectedItem) => selectedItem.id === item.id)
      ) {
        return;
      }
      this.selectedOptions = [...this.selectedOptions, item];
      this.$emit("result", this.selectedOptions);
    },
    unSelectItem(item) {
      this.selectedOptions = this.selectedOptions.filter(
        (selectedItem) => selectedItem.id !== item.id
      );
      this.$emit("result", this.selectedOptions);
    },
  },
  props: {
    posibleItems: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "Type...",
    },
    initialSelectedItems: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="sass" scoped>
.combobox__items__list
  align-items: flex-start
  display: flex
  flex-direction: column
  min-height: 36px
  padding: 10px
  width: 100%

.combobox__item
  align-items: center
  background: lighten(#78d64b, 30)
  border-radius: 15px
  color: darken(#78d64b, 30)
  display: flex
  font-weight: 600
  height: 32px
  justify-content: center
  margin: 10px 0
  padding: 0 10px 0 18px

.combobox__item__icon
  align-items: center
  border-radius: 100%
  color: darken(#78d64b, 30)
  cursor: pointer
  display: flex
  height: 20px
  justify-content: center
  margin-left: 10px
  width: 20px
</style>
