import Vue from "vue";
import Vuex from "vuex";
import Api from "../api";
import { cloneDeep } from "lodash";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    sf_accounts: [],
    sf_courses_by_account_id: {},
    sf_facilities_by_account_id: {},
    facility: {},
    departments_by_facility: {},
    unassigned_facilities: [],
    unassigned_departments: [],
    departments: {},
    userToken: null,
    activeAccount: {},
    alertRef: null,
    sf_products_by_account_id: {},
    sf_products: [],
    sf_courses: [],
    proto_courses: [],
    proto_by_account_id: {},
  },
  mutations: {
    setSalesforceAccounts(state, accounts) {
      Vue.set(state, "sf_accounts", accounts);
    },

    setSalesforceAccount(state, account) {
      Vue.set(state, "sf_account", account);
    },

    setSalesforceAccountCourses(state, { sf_account_courses, accountId }) {
      const accounts_courses = cloneDeep(state.sf_courses_by_account_id);
      accounts_courses[accountId] = sf_account_courses;
      Vue.set(state, "sf_courses_by_account_id", accounts_courses);
    },

    setProtoCoursesByAccount(state, { proto_courses, accountId }) {
      const proto_by_account_id = cloneDeep(state.proto_by_account_id);
      proto_by_account_id[accountId] = proto_courses;
      Vue.set(state, "proto_by_account_id", proto_by_account_id);
    },

    setSalesforceAccountProducts(state, { sf_account_products, accountId }) {
      const accounts_products = cloneDeep(state.sf_products_by_account_id);
      accounts_products[accountId] = sf_account_products;
      Vue.set(state, "sf_products_by_account_id", accounts_products);
    },
    setSalesforceProducts(state, products) {
      Vue.set(state, "sf_products", products);
    },
    setSalesforceCourses(state, courses) {
      Vue.set(state, "sf_courses", courses);
    },
    setSalesforceAccountFacilities(
      state,
      { sf_account_facilities, accountId }
    ) {
      const accounts_facilities = cloneDeep(state.sf_facilities_by_account_id);
      accounts_facilities[accountId] = sf_account_facilities;
      Vue.set(state, "sf_facilities_by_account_id", accounts_facilities);
    },

    setUnAssignedFacilities(state, facilities) {
      Vue.set(state, "unassigned_facilities", facilities);
    },

    setAddedFacilities(state, { facilities, accountId }) {
      const accounts_facilities = cloneDeep(state.sf_facilities_by_account_id);
      accounts_facilities[accountId] = [
        ...accounts_facilities[accountId],
        ...facilities,
      ];
      Vue.set(state, "sf_facilities_by_account_id", accounts_facilities);
    },

    setFacility(state, { facility, facilityId }) {
      const facilities = cloneDeep(state.facility);
      facilities[facilityId] = facility;
      Vue.set(state, "facility", facilities);
    },

    setFacilityDepartments(state, { departments, facilityId }) {
      const institution_departments = cloneDeep(state.departments_by_facility);
      institution_departments[facilityId] = departments;
      Vue.set(state, "departments_by_facility", institution_departments);
    },

    addUnassignedFacility(state, facility) {
      const unassignedFacilities = cloneDeep(state.unassigned_facilities);
      unassignedFacilities.push(facility);
      Vue.set(state, "unassigned_facilities", unassignedFacilities);
    },

    addUnassignedDepartment(state, department) {
      const unassignedDepartments = cloneDeep(state.unassigned_departments);
      unassignedDepartments.push(department);
      Vue.set(state, "unassigned_departments", unassignedDepartments);
    },

    removeUnassignedFacilities(state, facilities) {
      const unassignedFacilities = cloneDeep(state.unassigned_facilities);

      const remainingUnassignedFacilities = unassignedFacilities.filter(
        (facility) =>
          !facilities.some(
            (facilityB) => facilityB.institution_id == facility.institution_id
          )
      );

      Vue.set(state, "unassigned_facilities", remainingUnassignedFacilities);
    },

    removeUnassignedDepartments(state, departments) {
      const unassignedDepartments = cloneDeep(state.unassigned_departments);

      const remainingUnassignedDepartments = unassignedDepartments.filter(
        (department) =>
          !departments.some(
            (departmentB) => departmentB.department_id == department.id
          )
      );

      Vue.set(state, "unassigned_departments", remainingUnassignedDepartments);
    },

    removeFacilityFromAccount(state, { institutionId, accountId }) {
      const accounts_facilities = cloneDeep(state.sf_facilities_by_account_id);

      accounts_facilities[accountId] = accounts_facilities[accountId].filter(
        (facility) => facility.institution_id != institutionId
      );

      Vue.set(state, "sf_facilities_by_account_id", accounts_facilities);
    },

    removeDepartmentFromFacility(state, { departmentId, facilityId }) {
      const departments_by_facility = cloneDeep(state.departments_by_facility);

      departments_by_facility[facilityId] = departments_by_facility[
        facilityId
      ].filter((department) => department.id != departmentId);

      Vue.set(state, "departments_by_facility", departments_by_facility);
    },

    setUnAssignedDepartments(state, departments) {
      Vue.set(state, "unassigned_departments", departments);
    },

    setAddedDepartments(state, { departments, facilityId }) {
      const institution_departments = cloneDeep(state.departments_by_facility);
      institution_departments[facilityId] = [
        ...institution_departments[facilityId],
        ...departments,
      ];
      Vue.set(state, "departments_by_facility", institution_departments);
    },

    setDepartment(state, { department, departmentId }) {
      const departments = cloneDeep(state.departments);
      departments[departmentId] = department;
      Vue.set(state, "departments", departments);
    },

    setUserToken(state, { token }) {
      Vue.set(state, "userToken", token);
    },

    setEditingAccountCode(state, account) {
      Vue.set(state, "activeAccount", account);
    },

    setProtoCourses(state, courses) {
      Vue.set(state, "proto_courses", courses);
    },
  },
  actions: {
    async initializeApp({ commit, state }, alertRef) {
      state.alertRef = alertRef;
      const sf_accounts = await Api.getSalesforceAccounts();
      commit("setSalesforceAccounts", sf_accounts);
    },

    async refreshSalesforceAccounts({ commit }) {
      const sf_accounts = await Api.getSalesforceAccounts();
      commit("setSalesforceAccounts", sf_accounts);
    },

    async loadSalesforceAccount({ commit }, accountId) {
      const sf_account = await Api.getSalesforceAccount(accountId);
      commit("setSalesforceAccount", sf_account);
    },

    async loadSalesforceAccountCourses({ commit }, accountId) {
      const sf_account_courses = await Api.getSalesforceAccountCourses(
        accountId
      );
      commit("setSalesforceAccountCourses", {
        sf_account_courses,
        accountId,
      });
    },

    async loadSalesforceProducts({ commit }) {
      const sf_products = await Api.getSFProducts();
      commit("setSalesforceProducts", sf_products);
    },

    async loadProtoCourses({ commit }) {
      const proto_courses = await Api.getProtoCourses();
      commit("setProtoCourses", proto_courses);
    },

    async loadProtoCoursesByAccount({ commit }, accountId) {
      const proto_courses = await Api.getProtoCoursesByAccount(accountId);
      commit("setProtoCoursesByAccount", {
        proto_courses,
        accountId,
      });
    },

    async loadSalesforceCourses({ commit }) {
      const sf_courses = await Api.getSFCourses();
      commit("setSalesforceCourses", sf_courses);
    },

    async loadSalesforceAccountProducts({ commit }, accountId) {
      const sf_account_products = await Api.getSalesforceAccountProducts(
        accountId
      );
      commit("setSalesforceAccountProducts", {
        sf_account_products,
        accountId,
      });
    },

    async loadSalesforceAccountFacilities({ commit }, accountId) {
      const sf_account_facilities = await Api.getSalesforceAccountFacilities(
        accountId
      );
      commit("setSalesforceAccountFacilities", {
        sf_account_facilities,
        accountId,
      });
    },

    async loadUnAssignedFacilities({ commit }) {
      const all_facilities = await Api.getAllFacilities();
      const assigned_facilities = await Api.getAssignedFacilities();
      const unassigned_facilities = all_facilities.filter(
        (facility) =>
          !assigned_facilities.some(
            (assigned_facility) =>
              assigned_facility.institution_id === facility.institution_id
          )
      );
      commit("setUnAssignedFacilities", unassigned_facilities);
    },

    async addFacilities({ commit }, { facilities, accountId }) {
      const response = await Api.addFacilities(facilities);
      commit("removeUnassignedFacilities", facilities);
      commit("setAddedFacilities", { facilities: response, accountId });
    },

    async addProducts({ commit }, { products, accountId }) {
      await Api.addProducts(products, accountId);
      // load products and courses
      commit("setSalesforceAccountCourses", {
        sf_account_courses: [],
        accountId,
      });
      this.dispatch("loadSalesforceAccountProducts", accountId);
      this.dispatch("loadSalesforceAccountCourses", accountId);
    },

    async addCourses({ commit }, { courses, accountId }) {
      await Api.addProtoCourses(courses, accountId);
      // load products and courses
      commit("setProtoCoursesByAccount", {
        proto_courses: [],
        accountId,
      });
      this.dispatch("loadProtoCoursesByAccount", accountId);
    },

    async loadFacility({ commit }, facilityId) {
      const facility = await Api.getFacility(facilityId);
      commit("setFacility", { facility, facilityId });
    },

    async loadFacilityDepartments({ commit }, facilityId) {
      const departments = await Api.getDepartmentsByFacility(facilityId);
      commit("setFacilityDepartments", { departments, facilityId });
    },

    async removeAccountFacility({ commit }, { institutionId, accountId }) {
      const removedFacility = await Api.removeAccountFacility(institutionId);
      if (!removedFacility) {
        return;
      }
      commit("addUnassignedFacility", removedFacility.facility);
      commit("removeFacilityFromAccount", { institutionId, accountId });
    },

    async removeFacilityDepartment(
      { commit, state },
      { departmentId, facilityId }
    ) {
      const removedDepartment = await Api.removeFacilityDepartment({
        departmentId,
        token: state.userToken,
        facilityId,
      });
      commit("addUnassignedDepartment", removedDepartment);
      commit("removeDepartmentFromFacility", { departmentId, facilityId });
    },

    async softDeleteFacility({ commit, state }, institutionId) {
      const softDeleteFacility = await Api.softDeleteFacility(institutionId);

      const facility = state.unassigned_facilities.find(
        (facility) => facility.institution_id == institutionId
      );

      if (facility) {
        commit("removeUnassignedFacilities", softDeleteFacility);
      } else {
        const removedFacility = await Api.removeAccountFacility(institutionId);

        try {
          const accountId = removedFacility.account_id;

          // Error
          commit("removeFacilityFromAccount", { institutionId, accountId });

          await axios.get(
            `${process.env.VUE_APP_SF_API}/update/deletefacility?institutionid=${institutionId}`
          );
        } catch (e) {
          console.error(e);
        }
      }
    },

    async updateFacility({ commit }, facility) {
      const updatedFacility = await Api.updateFacility(facility);
      commit("setFacility", {
        facility: updatedFacility,
        facilityId: updatedFacility.id,
      });
    },

    async addDepartments({ commit, state }, { departments, facilityId }) {
      const response = await Api.addDepartments(departments, state.userToken);
      commit("removeUnassignedDepartments", departments);
      commit("setAddedDepartments", { departments: response, facilityId });
    },

    async addCourseProduct({ commit }, { courses, product_id }) {
      await Api.addCourseProduct(courses, product_id);
      commit("setSalesforceCourses", []);
      this.dispatch("loadSalesforceProducts");
    },

    async loadUnAssignedDepartments({ commit }, { facilityId }) {
      const unassigned_departments = await Api.getUnassignedDepartments({
        facilityId,
      });
      commit("setUnAssignedDepartments", unassigned_departments);
    },

    async createNewDepartment({ commit, state }, { name, facilityId }) {
      const newDepartment = await Api.createNewDepartment(
        name,
        facilityId,
        state.userToken
      );
      commit("setAddedDepartments", {
        departments: [newDepartment],
        facilityId,
      });
      alert("Department created successfully");
    },

    async createSalesforceProduct({ commit, state }, { name }) {
      const productId = `${Math.random()
        .toString(36)
        .substring(2, 10)}_${Math.random().toString(36).substring(2, 10)}`;
      const newProduct = await Api.createSalesforceProduct(name, productId);
      alert("Product created successfully");
      commit("setSalesforceProducts", [newProduct, ...state.sf_products]);
    },

    async loadDepartment({ commit }, departmentId) {
      const department = await Api.getDepartment(departmentId);
      commit("setDepartment", { department, departmentId });
    },

    async updateDepartment({ commit }, department) {
      const updatedDepartment = await Api.updateDepartment(department);
      commit("setDepartment", {
        department: updatedDepartment,
        departmentId: department.id,
      });
    },

    establishEditingAccountCode({ commit }, account) {
      commit("setEditingAccountCode", account);
    },

    async updateAccountCode({ commit }, account) {
      const newCode = await Api.updateAccountCode(account);
      account.code = newCode;
      commit("setEditingAccountCode", account);
    },

    createAlert({ state }, { message, type }) {
      state.alertRef.addAlert(message, type, 5000, true);
    },
  },
  getters: {
    getFacilityInfo: (state) => (facilityId) => {
      return (
        state.facility[facilityId] || {
          name: "",
          address: "",
          state_province: "",
          postal_code: "",
        }
      );
    },
    getDepartmentFacilities: (state) => (facilityId) => {
      return state.departments_by_facility[facilityId] || [];
    },
    getDepartmentInfo: (state) => (departmentId) => {
      return (
        state.departments[departmentId] || {
          id: departmentId,
          name: "",
        }
      );
    },
  },
});
