<template>
  <div class="table-container">
    <div class="mt-3" v-if="$store.state.sf_products.length <= 0">
      <HSSkeleton width="280px" height="30px" />
      <div class="mt-2 d-flex">
        <HSSkeleton width="80px" height="30px" />
        <HSSkeleton class="ml-3" width="150px" height="30px" />
      </div>
      <HSSkeleton class="mt-2" width="100%" height="500px" />
    </div>
    <div v-else class="flex-row d-flex justify-content-around">
      <div class="w-50 p-4">
        <HSDataTable
          dataTest="products-table"
          title="Products"
          :itemsPerPage="20"
          :headers="dataTableHeaders"
          :items="$store.state.sf_products"
        >
          <template #name="{ item }">
            <router-link
              :to="'/products/' + item.product_id"
              data-test="product-link"
            >
              {{ item.name }}
            </router-link>
          </template>
        </HSDataTable>
      </div>
      <div class="w-50 p-4">
        <HSButton
          v-if="!addingProducts"
          class="mt-3 mb-3 w-100"
          data-test="add-product-button"
          @onClick="handleAddProduct"
          label="Create new Product"
        />
        <div v-else>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h4>Creating new Product</h4>
            <div class="d-flex align-items-center mb-3">
              <HSButton
                data-test="products-create-product-button-cancel"
                label="Cancel"
                type="is-danger"
                @onClick="handleCancelProduct"
              />
            </div>
          </div>
          <form action="" @submit.prevent="createNewProduct">
            <div class="d-flex flex-column mb-3">
              <label for="">Product Name</label>
              <input
                type="text"
                v-model="newProductName"
                data-test="products-create-product-input-name"
              />
            </div>
            <p
              v-show="formInvalid"
              class="has-warning"
              data-test="product-create-error"
            >
              You must fill all the fields to create a new product.
            </p>
            <HSButton
              data-test="products-create-product-button-submit"
              label="Create Product"
              type="is-success"
              :disabled="!newProductName"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HSDataTable,
  HSSkeleton,
  HSButton,
} from "@healthscholars/healthscholars-vue";
export default {
  components: {
    HSDataTable,
    HSSkeleton,
    HSButton,
  },
  data() {
    return {
      addingProducts: false,
      newProductName: "",
      formInvalid: false,
      dataTableHeaders: [
        {
          label: "Name",
          key: "name",
          searchable: true,
          visible: true,
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("loadSalesforceProducts");
  },
  methods: {
    handleAddProduct() {
      this.addingProducts = true;
    },
    handleCancelProduct() {
      this.addingProducts = false;
    },
    createNewProduct() {
      if (!this.newProductName) {
        this.formInvalid = true;
        return;
      }
      this.$store.dispatch("createSalesforceProduct", {
        name: this.newProductName,
      });
      this.newProductName = "";
    },
  },
};
</script>

<style scoped lang="sass">
.table-container
  width: 80%
  margin: 20px auto

.account-code
  display: flex
  .edit-icon
    visibility: hidden
    margin-left: 0.75em
    font-size: 20px
  &:hover
    cursor: pointer
  &:hover > p
    text-decoration: underline
  &:hover > .edit-icon
    visibility: visible
</style>
